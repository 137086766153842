export default {
  farmsInfo: [
    {
      name: "송〇〇농장",
      locate: "강원도 춘천시",
      item: "토마토",
      scale: "2000평",
    },
    {
      name: "디〇팜",
      locate: "경기도 평택시",
      item: "토마토",
      scale: "1000평",
    },
    {
      name: "늘햇〇팜",
      locate: "경상남도 진주시",
      item: "토마토",
      scale: "8000평",
    },
    {
      name: "리〇그린",
      locate: "전라남도 순천시",
      item: "토마토",
      scale: "10000평",
    },
    {
      name: "성〇농장",
      locate: "전라남도 영암군",
      item: "고추",
      scale: "1000평",
    },
    {
      name: "우〇식 농부",
      locate: "전라남도 영암군",
      item: "토마토",
      scale: "300평",
    },
    {
      name: "세〇팜",
      locate: "경기도 고양시",
      item: "토마토",
      scale: "1000평",
    },
    {
      name: "영〇반디농장",
      locate: "경기도 고양시",
      item: "토마토,고추",
      scale: "1000평",
    },
    {
      name: "소〇농장",
      locate: "경기도 고양시",
      item: "호박",
      scale: "1000평",
    },
    {
      name: "동〇한농종묘",
      locate: "강원도 영월군",
      item: "토마토",
      scale: "1000평",
    },
    {
      name: "서〇네농장",
      locate: "경기도 고양시",
      item: "토마토",
      scale: "4700평",
    },
    {
      name: "김〇자 농부",
      locate: "경상남도 고성군",
      item: "토마토,고추",
      scale: "3700평",
    },
    {
      name: "백〇운 농부",
      locate: "경기도 평택시",
      item: "토마토",
      scale: "3000평",
    },
    {
      name: "신〇근 농부",
      locate: "경기도 고양시",
      item: "토마토",
      scale: "4000평",
    },
    {
      name: "홍〇희 농부",
      locate: "전라남도 화순군",
      item: "토마토",
      scale: "1000평",
    },
    {
      name: "조〇정 농부",
      locate: "강원도 영월군",
      item: "토마토",
      scale: "1700평",
    },
    {
      name: "박〇민 농부",
      locate: "경상남도 고성군",
      item: "토마토,가지",
      scale: "1000평",
    },
    {
      name: "김〇슬 농부",
      locate: "전라북도 전주시",
      item: "토마토",
      scale: "4500평",
    },
    {
      name: "지〇린 농부",
      locate: "경기도 안성시",
      item: "고추",
      scale: "2200평",
    },
    {
      name: "홍〇희 농부",
      locate: "전라북도 김제시",
      item: "호박",
      scale: "1700평",
    },
    {
      name: "맛〇팜",
      locate: "경상남도 함양군",
      item: "가지",
      scale: "1700평",
    },
  ],
};
