import api from "../api/defaultAPI";

export default {
  //예약 신청
  getPossibleDate: (params) => {
    return api.get("/api/reserve/check/date", {
      params: params,
      headers: { "Content-Type": "application/json" },
    });
  },
  getUserInfo: (params) => {
    return api.get("/api/reserve/user", {
      params: params,
      headers: { "Content-Type": "application/json" },
    });
  },
  getCropList: () => {
    return api.get("/api/reserve/croplist", {
      headers: { "Content-Type": "application/json" },
    });
  },
  getCropItemList: (params) => {
    return api.get("/api/reserve/item", {
      params: params,
      headers: { "Content-Type": "application/json" },
    });
  },
  saveReservationInfo: (params) => {
    return api.post("/api/reserve", JSON.stringify(params), {
      headers: { "Content-Type": "application/json" },
    });
  },

  //예약 조회
  getUserBookingNum: (params) => {
    return api.get("/api/reserve/" + params, {
      headers: { "Content-Type": "application/json" },
    });
  },
  getUserList: (params) => {
    return api.get("/api/reserve/check", {
      params: params,
      headers: { "Content-Type": "application/json" },
    });
  },
  getUser: (params) => {
    return api.get("/api/reserve/info/" + params, {
      headers: { "Content-Type": "application/json" },
    });
  },
};
