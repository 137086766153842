
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import * as api from "@/api/index.js";

@Component({
  components: {},
})
export default class ReservationCheck extends Vue {
  @Prop({ required: true }) pageStatus: string = "";

  openTable: boolean = false;
  status: string = "";
  tab: any = "";
  tabList: any = ["예약번호 조회", "주문자 + 연락처 조회"];
  board_height: number = 0;
  search: any = {
    code: "",
    name: "",
    phone: "",
  };
  header: any = [
    { text: "예약번호", value: "code" },
    { text: "품종", value: "itemName" },
    { text: "신청일", value: "reserve_Date" },
  ];
  reservationList: any[] = [];
  infoList: any = [
    { title: "예약번호", value: "code", data: "" },
    { title: "주문자", value: "name", data: "" },
    { title: "재배면적", value: "area", data: "" },
    { title: "연락처", value: "phone", data: "" },
    { title: "품종", value: "itemName", data: "" },
    { title: "수량", value: "quantity", data: "" },
    { title: "주소", value: "address", data: "" },
    { title: "요청사항", value: "memo", data: "" },
  ];

  @Watch("tab")
  resetInfo() {
    this.search.code = "";
    this.search.name = "";
    this.search.phone = "";
  }
  @Watch("pageStatus")
  check() {
    if (this.pageStatus == "search") {
      this.status = "search";
    }
    return this.status;
  }
  created() {
    this.onResize();
  }
  mounted() {
    this.status = "search";
    this.search.code = "";
    this.search.name = "";
    this.search.phone = "";
  }

  onResize() {
    this.board_height = window.innerHeight - 64 - 180;
  }
  searchInfo(tab: number) {
    this.reservationList = [];
    let data = {};
    this.board_height = window.innerHeight - 64;
    //예약확인 페이지

    if (tab == 0 && this.search.code != "" && this.search.phone != "") {
      data = {
        code: this.search.code,
        phone: this.search.phone.replaceAll("-", ""),
        type: 2,
      };
    } else if (tab == 1 && this.search.name != "" && this.search.phone != "") {
      data = {
        code: this.search.name,
        phone: this.search.phone.replaceAll("-", ""),
        type: 1,
      };
    } else {
      return this.$swal({
        title: "정보를 입력 후 조회해주세요 ",
        icon: "error",
        position: "top",
        showCancelButton: false,
        showConfirmButton: false,
        toast: true,
        timer: 1500,
      });
    }

    api.reservation
      .getUserList(data)
      .then((response: any) => {
        if (response.status == 200) {
          this.reservationList = response.data.responseData;
          this.moreInfo(this.reservationList[0]);
          this.status = "detail";
          return this.reservationList;
        }
      })
      .catch((error) => {
        return this.$swal({
          title: "정보가 존재하지않습니다. 다시 재시도해주세요 ",
          icon: "error",
          position: "top",
          showCancelButton: false,
          showConfirmButton: false,
          toast: true,
          timer: 1500,
        });
      })
      .finally(() => {});
  }
  moreInfo(item: any) {
    api.reservation
      .getUser(item.id)
      .then((response: any) => {
        if (response.status == 200) {
          let temp = Object.entries(response.data.responseData);

          temp.forEach((data: any) => {
            for (var i = 0; i < this.infoList.length; i++) {
              if (this.infoList[i].value == data[0]) {
                this.infoList[i].data = data[1];
              }
            }
          });
        }
        return this.infoList;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.openTable = true;
      });
  }
  back() {
    this.board_height = window.innerHeight - 64;
    this.openTable = false;
    this.status = "search";
    this.search.code = "";
    this.search.name = "";
    this.search.phone = "";
  }
}
