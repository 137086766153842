<template>
  <v-app class="ma-0 pa-0">
    <v-main>
      <AppHeader
        @move-to-sample="MoveToSample"
        @move-to-info="MoveToInfo"
        @current-menu="changeMenu"
        class="header"
      ></AppHeader>
      <AppContent
        ref="methodRequest"
        v-show="currentPage == 'appContent'"
      ></AppContent>
      <ReservationCheck
        v-show="currentPage == 'check'"
        :pageStatus="status"
      ></ReservationCheck>
      <ReservationRequest
        v-show="currentPage == 'request'"
        @current-check="changecheck"
      ></ReservationRequest>
      <AppFooter></AppFooter>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import AppContent from "./views/content/AppContent.vue";
import ReservationCheck from "./views/content/ReservationCheck.vue";
import ReservationRequest from "./views/content/ReservationRequest.vue";

export default {
  name: "App",
  metaInfo() {
    return {
      title: "한국표준육묘",
      // titleTemplate: "test | %s",
      meta: [
        {
          name: "title",
          vmid: "title",
          content: "한국표준육묘",
        },
        {
          name: "description",
          vmid: "description",
          content:
            "한국표준육묘는 국내 최초의 수직형 LED 육묘업체으로 최적의 육묘 솔루션을 제공하여 우수한 품질의 묘를 생산합니다",
        },
        {
          property: "og:type",
          content: "website",
          vmid: "og:type",
        },
        {
          property: "og:title",
          content: "한국표준육묘",
          vmid: "og:title",
        },
        {
          property: "og:description",
          content:
            "한국표준육묘는 국내 최초의 수직형 LED 육묘업체으로 최적의 육묘 솔루션을 제공하여 우수한 품질의 묘를 생산합니다",
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: "@/components/logo_seedling.png",
          vmid: "og:image",
        },
        {
          property: "og:image:alt",
          content: "한국표준육묘 LOGO",
          vmid: "og:image:alt",
        },
        {
          property: "og:url",
          content: "https://ksseedling.com/",
          vmid: "og:url",
        },
        {
          name: "naver-site-verification",
          vmid: "naver-site-verification",
          content: "1319c48ad273f4f3c3452800928e48406af69d5d",
        },
        {
          name: "naver-site-verification",
          vmid: "naver-site-verification",
          content: "83feca07859e5bca82724c34dcb8cf9f81467cfc",
        },
      ],
    };
  },
  data: () => ({
    isSample: false,
    isInfo: false,
    currentPage: "",
    status: "",
  }),

  components: {
    AppHeader,
    AppContent,
    AppFooter,
    ReservationCheck,
    ReservationRequest,
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  created() {},

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.currentPage = "appContent";
  },

  methods: {
    onResize() {
      /* this.isMobile = window.innerWidth < 600 */
    },
    MoveToSample(data) {
      this.$refs.methodRequest.MoveToSample(data);
    },
    MoveToInfo(data) {
      this.$refs.methodRequest.MoveToInfo(data);
    },
    changeMenu(data) {
      this.currentPage = data;
      if (data == "request") {
        this.status = "search";
      }
      return this.currentPage;
    },
    changecheck() {
      this.currentPage = "check";
    },
  },
};
</script>
<style>
@font-face {
  font-family: "Puradak_header";
  src: url("/src/assets/fonts/Puradak\ Gentle\ Gothic.ttf") format("woff");
  font-style: normal;
  font-weight: normal;
}

.header {
  font-family: "Puradak_header";
}
</style>
