import api from "../api/defaultAPI";

export default {
  /*   postCustomerInfo: (params) =>
    api.post("/api/customer", JSON.stringify(params)),
   */
  postCustomerInfo: (params) => {
    return api.post("/api/customer", params, {
      headers: { "Content-Type": "application/json" },
    });
  },

  getBoardList: () => {
    return api.get("/api/newsfeed/list", {
      headers: { "Content-Type": "application/json" },
    });
  },

  getImageBoardDetailInfo: (params) => {
    return api.get("/api/newsfeed/" + params, {
      headers: { "Content-Type": "application/json" },
    });
  },
};
