
import { Viewer, Editor } from "@toast-ui/vue-editor";
import { Vue, Component, Watch } from "vue-property-decorator";
import * as api from "@/api/index.js";
import cfg from "./farmsInfo.js";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import "highlight.js/styles/github.css";
import codeSyntaxHightlight from "@toast-ui/editor-plugin-code-syntax-highlight";

@Component({
  components: {
    Editor,
    Viewer,
    codeSyntaxHightlight,
  },
})
export default class AppContent extends Vue {
  $refs!: {
    viewer: HTMLFormElement;
    requestInput: HTMLInputElement;
    phoneNumber2: HTMLInputElement;
  };

  gridCfg: any = {
    totalCount: 0,
    page: 1,
    perPage: 9,
  };
  pageBoard: any = [];
  detailBoard: any = [];
  board: any = [];
  content: string = "";
  newDialog: boolean = false;
  page: number = 1;
  userInfo: any = {
    customerName: "",
    item: "",
    area: "",
    phoneNumber: "",
  };
  radioValues: string = "Y";
  columns: number = 3;
  items: any = [
    {
      src: require("./photo/farm_3.png"),
    },
    {
      src: require("./photo/farm_1.png"),
    },
    {
      src: require("./photo/farm_2.png"),
    },
  ];
  width: number = 0;
  isShowValue: boolean = true;

  @Watch("gridCfg", { deep: true })
  check() {
    let tempArr = [];
    tempArr = [...this.board];

    this.pageBoard = tempArr.slice(
      (this.gridCfg.page - 1) * this.gridCfg.perPage,
      this.gridCfg.page * this.gridCfg.perPage
    );

    return this.pageBoard;
  }

  get farmsInfo() {
    return cfg.farmsInfo;
  }

  created() {
    this.getBoard();
  }
  mounted() {
    window.addEventListener("resize", this.handleResize);
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize(event: any) {
    //모바일화면일때만 v-if해주기
    this.width = window.innerWidth;

    if (this.width > 600) {
      this.isShowValue = true;
    } else {
      this.isShowValue = false;
    }
  }

  MoveToSample() {
    var element = document.querySelector("#sample");
    if (element !== null) {
      var location = (element as HTMLElement).offsetTop;
      window.scrollTo({ top: location, behavior: "smooth" });
    }
  }
  checkPhoneNumber1() {
    if (this.userInfo.phoneNumber_2.length === 4) {
      const phoneNumber2Input = this.$refs.phoneNumber2 as HTMLInputElement;
      phoneNumber2Input.focus();
    }
  }
  checkPhoneNumber2() {
    if (this.userInfo.phoneNumber_3.length === 4) {
      const requestInput = this.$refs.requestInput as HTMLInputElement;
      requestInput.focus();
    }
  }
  MoveToInfo() {
    var element = document.querySelector("#info");
    if (element !== null) {
      var location = (element as HTMLElement).offsetTop;
      window.scrollTo({ top: location, behavior: "smooth" });
    }
  }
  preSubmit() {
    let body = {
      area: this.userInfo.area,
      customerName: this.userInfo.customerName,
      phoneNumber: this.userInfo.phoneNumber,
      request: this.userInfo.item,
    };
    if (body.customerName == "") {
      alert("이름을 입력해주세요.");
    } else if (body.area == "") {
      alert("재배면적을 입력해주세요.");
    } else if (body.phoneNumber == "") {
      alert("연락처를 입력해주세요.");
    } else if (body.request == "") {
      alert("요청사항을 입력해주세요.");
    } else {
      api.landing
        .postCustomerInfo(body)
        .then((res: any) => {
          alert("참여가 완료되었습니다.");
          this.userInfo = "";
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          console.log("항상 마지막에 실행");
        });
    }
  }
  getBoard() {
    api.landing.getBoardList().then((response: any) => {
      this.board = response.data.responseData;
      this.gridCfg.totalCount = this.board.length;

      this.board.forEach((value: any) => {
        if (value.content.indexOf("<img src=") != -1) {
          var s = value.content.indexOf("<img src=") + 10;
          var e = value.content.indexOf("alt") - 2;
          value["url"] = value.content.substring(s, e);
        } else {
          value["url"] = "";
        }
      });
    });
  }
  detailNews(item: number) {
    api.landing
      .getImageBoardDetailInfo(item)
      .then((response: any) => {
        this.detailBoard = response.data.responseData;
        this.content = this.detailBoard.content;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (this.$refs.viewer != undefined) {
          this.$refs.viewer.invoke("setMarkdown", this.content, true);
        }
        this.newDialog = true;
      });
  }
  close() {
    this.newDialog = false;
    this.content = "";
  }
}
