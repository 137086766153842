
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class AppHeader extends Vue {
  isSample: boolean = false;
  isInfo: boolean = false;

  MoveToSample() {
    this.$emit("current-menu", "appContent");
    this.$emit("move-to-sample", this.isSample);
  }
  MoveToInfo() {
    this.$emit("current-menu", "appContent");
    this.$emit("move-to-info", this.isInfo);
  }
  request() {
    this.$emit("current-menu", "request");
  }
  check() {
    this.$emit("current-menu", "check");
  }
}
