
import { Vue, Component, Watch } from "vue-property-decorator";
import * as api from "@/api/index.js";

@Component({
  components: {},
})
export default class ReservationRequest extends Vue {
  doneMenu: boolean = false;
  board_height: number = 0;
  e1: number = 1;
  picker: string = "";
  oneMonth: string = "";
  types: any[] = [
    { title: "실생묘", code: "TYPE01", count: "", value: "1" },
    { title: "접목묘", code: "TYPE02", count: "", value: "2" },
  ];
  cropData: any[] = [];
  kindData: any[] = [];
  productShow: boolean = true;
  personalShow: boolean = false;
  survey: string = "";
  info: any = {
    code: "",
    date: "",
    type: "",
    crop: "",
    kind: "",
    itemId: "",
    quantity: "", //수량
    name: "", //주문자명
    area: "", //면적
    phone: "", //연락처
    address: {
      //zoneCode: "",
      roadAddress: "",
      detailAddress: "",
    }, //주소
    order_memo: "", //요청사항
    agree: "Y",
  };
  resetInfo: any = {
    date: "",
    type: "",
    crop: "",
    kind: "",
    itemId: "",
    quantity: "", //수량
    name: "", //주문자명
    area: "", //면적
    phone: "", //연락처
    address: {
      //zoneCode: "",
      roadAddress: "",
      detailAddress: "",
    }, //주소
    order_memo: "", //요청사항
    agree: "Y",
  };
  kindShow: boolean = true; //직접입력 조건을 위함
  name: string = ""; //최종등록된 user
  code: string = ""; //최종등록된 user

  @Watch("info", { deep: true })
  checkInfo() {
    /*
    if (
      this.info.kind != "" &&
      this.info.crop != "" &&
      this.info.quantity != ""
    ) {
      setTimeout(() => (this.productShow = false), 1000);
      this.personalShow = true;
    } else if (
      this.info.quantity != "" &&
      this.info.crop != null &&
      this.info.kind != null
    ) {
      setTimeout(() => (this.productShow = false), 1000);
      this.personalShow = true;
    } else if (
      (this.info.kind == "" &&
        this.info.crop == "" &&
        this.info.quantity == "") ||
      this.info.crop == null ||
      this.info.quantity == null
    ) {
      this.productShow = true;
      this.personalShow = false;
    }
    */

    if (this.info.name != "" && this.info.phone) {
      this.getUser(this.info.name, this.info.phone);
    }
  }
  @Watch("info.kind")
  text() {
    if (this.info.kind.id == "none") {
      this.kindShow = false;
      this.info.kind = "";
    }
  }
  @Watch("info.phone")
  returnPhone() {
    this.info.phone = this.info.phone
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    return this.info.phone;
  }

  created() {
    this.e1 = 1;
    this.info = Object.assign({}, this.resetInfo);
    this.onResize();
    this.nextMonth();
  }
  mounted() {
    this.e1 = 1;
  }
  onResize() {
    this.board_height = window.innerHeight - 64 - 180;
  }
  nextMonth() {
    const now = new Date();
    const utc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    const koreaTimeDiff = 9 * 60 * 60 * 1000;
    const koreaToday = new Date(utc + koreaTimeDiff);

    let pastMonth = +1;
    var tempTwoMonth = new Date(
      koreaToday.setMonth(koreaToday.getMonth() + pastMonth)
    );

    let oneMonthYear = tempTwoMonth.getFullYear();
    let oneMonthMonth = ("0" + (tempTwoMonth.getMonth() + 1)).slice(-2);
    let oneMonthDay = ("0" + tempTwoMonth.getDate()).slice(-2);

    this.oneMonth = oneMonthYear + "-" + oneMonthMonth + "-" + oneMonthDay;
    this.info.date = this.oneMonth;
    this.getSchedule();
  }
  getUser(name: any, phone: any) {
    let data = {
      name: name,
      phone: phone.replaceAll("-", ""),
    };
    api.reservation
      .getUserInfo(data)
      .then((response: any) => {
        if (response.status == 200) {
          this.info.area = response.data.responseData.area;
          this.info.address.roadAddress = response.data.responseData.address;
          return this.info;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getSchedule() {
    let data = {
      date: this.info.date,
    };

    api.reservation
      .getPossibleDate(data)
      .then((response: any) => {
        if (response.status == 200) {
          this.types.forEach((value: any) => {
            response.data.responseData.forEach((data: any) => {
              if (data.code == value.code) {
                value.count = data.total_count;
              }
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCrop() {
    this.cropData = [];

    api.reservation
      .getCropList()
      .then((response: any) => {
        if (response.status == 200) {
          this.cropData = response.data.responseData;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCropItem() {
    this.kindData = [];
    this.kindShow = true;
    let data = {
      crop_code: this.info.crop.code,
    };

    api.reservation
      .getCropItemList(data)
      .then((response: any) => {
        if (response.status == 200) {
          this.kindData = response.data.responseData;
        }
        this.kindData.push({
          id: "none", //기존의 품종코드와 구분을 주기 위함
          name: "직접 입력",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  openPostcode() {
    new (window as any).daum.Postcode({
      oncomplete: (data: any) => {
        //this.info.address.zoneCode = data.zonecode;
        this.info.address.roadAddress = data.roadAddress;
      },
    }).open();
  }
  done() {
    this.name = "";
    this.code = "";
    let data = {};
    //기존 품종
    if (this.kindShow) {
      data = {
        name: this.info.name,
        phone: this.info.phone.replaceAll("-", ""),
        area: this.info.area,
        address: `${this.info.address.roadAddress} ${this.info.address.detailAddress}`,
        itemId: this.info.kind.id,
        quantity: this.info.quantity,
        order_memo: this.info.order_memo,
        agree: this.info.agree,
        reserve_date: this.info.date,
      };
    }
    //신규 품종
    if (!this.kindShow) {
      data = {
        name: this.info.name,
        phone: this.info.phone.replaceAll("-", ""),
        area: this.info.area,
        address: `${this.info.address.roadAddress} ${this.info.address.detailAddress}`,
        itemName: this.info.kind,
        quantity: this.info.quantity,
        order_memo: this.info.order_memo,
        agree: this.info.agree,
        reserve_date: this.info.date,
      };
    }

    api.reservation
      .saveReservationInfo(data)
      .then((response: any) => {
        if (response.status == 200) {
          this.name = this.info.name;
          this.code = response.data.responseData.code;

          setTimeout(() => (this.doneMenu = true), 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.$emit("current-check", "check");
        this.e1 = 1;
        this.info = Object.assign({}, this.resetInfo);
        this.info.address.roadAddress = "";
        //setTimeout(() => (this.doneMenu = false), 1000);
      });
  }
  back(data: number) {
    if (data == 2) {
      this.e1 = 1;
      this.info.name = "";
      this.info.crop = "";
      this.info.kind = "";
      this.info.itemId = "";
      this.info.quantity = "";
      this.info.area = "";
      this.info.phone = "";
      this.info.area = "";
      this.info.address.roadAddress = "";
      this.info.address.detailAddress = "";
      this.info.order_memo = "";
      this.info.agree = "Y";
    }
  }
  nextStep(data: number) {
    if (data == 1) {
      if (this.info.date == "") {
        return this.$swal({
          title: "날짜가 선택되지않았습니다. ",
          icon: "error",
          position: "top",
          showCancelButton: false,
          showConfirmButton: false,
          toast: true,
          timer: 1500,
        });
      } else if (this.info.type == undefined || this.info.type == "") {
        return this.$swal({
          title: "모종이 선택되지않았습니다. ",
          icon: "error",
          position: "top",
          showCancelButton: false,
          showConfirmButton: false,
          toast: true,
          timer: 1500,
        });
      }
      {
        this.getCrop();
        this.e1 = 2;
      }
    } else if (data == 2) {
      if (
        this.info.name == "" ||
        this.info.area == "" ||
        this.info.phone == "" ||
        this.info.address.roadAddress == "" ||
        this.info.survey == ""
      ) {
        return this.$swal({
          title: "빈칸이 존재하여 확인해주세요",
          icon: "error",
          position: "top",
          showCancelButton: false,
          showConfirmButton: false,
          toast: true,
          timer: 1500,
        });
      } else {
        this.e1 = 3;
      }
    }
  }
}
